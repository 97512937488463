import clsx from 'clsx';

import React, { ComponentProps, forwardRef } from 'react';

import { FasChevronDown } from '@virginmediao2/momentum-icons/icons/fas';

import { Icon } from '@/components/momentum/components/icon';

import { FormItem } from '../form-item';
import { FormItemProps } from '../form-item/types';
import { useFieldIds } from '../hooks/useFieldIds';
import { SharedFormFieldProps } from '../types/types';
import styles from './form-select.module.scss';

export interface FormSelectProps
  extends Omit<ComponentProps<'select'>, 'id' | 'name' | 'placeholder'> {
  autocomplete?: string;
  placeholder?: string;
  affix?: React.ReactNode;
  affixWidth?: '1ch' | '2ch' | '3ch';
  options: string[];
}

export const FormSelect = forwardRef<
  HTMLSelectElement,
  FormSelectProps & FormItemProps & SharedFormFieldProps
>(
  (
    {
      id,
      name,
      dataTestId,
      autocomplete,
      placeholder = 'Please Select',
      disabled = false,
      readonly = false,
      required = false,
      affix,
      affixWidth = '2ch',
      hasDescription = false,
      label,
      description,
      error,
      hideLabels,
      marginBottom,
      options,
      value = '',
      ...props
    },
    ref
  ) => {
    const isError = !!error;
    const fieldIds = useFieldIds({
      id,
      isError: !!isError,
      hasDescription,
    });

    return (
      <FormItem
        fieldIds={fieldIds}
        label={label}
        error={error}
        description={description}
        hideLabels={hideLabels}
        marginBottom={marginBottom}
      >
        <div
          className={clsx(styles['form-input'], styles['form-input--select'], {
            [styles['form-input--disabled']]: disabled,
            [styles['form-input--readonly']]: readonly,
            [styles['form-input--error']]: isError,
          })}
          data-testid={`form-select--${fieldIds.id}`}
        >
          {affix && (
            <div
              className={clsx(
                styles['form-input__affix'],
                styles['form-input__affix--start']
              )}
            >
              {affix}
            </div>
          )}
          <select
            id={id}
            name={name}
            className={clsx(
              styles['form-input__control'],
              styles['form-input__control--select'],
              {
                [styles[`form-input__control--affix--start--1ch`]]:
                  affix && affixWidth === '1ch',
                [styles[`form-input__control--affix--start--2ch`]]:
                  affix && affixWidth === '2ch',
                [styles[`form-input__control--affix--start--3ch`]]:
                  affix && affixWidth === '3ch',
              }
            )}
            aria-describedby={fieldIds.describedBy}
            aria-invalid={!!isError ? 'true' : undefined}
            aria-disabled={!!disabled ? 'true' : undefined}
            aria-required={!!required ? 'true' : undefined}
            aria-readonly={!!readonly ? 'true' : undefined}
            autoComplete={autocomplete}
            disabled={disabled || readonly}
            defaultValue={value}
            data-testid={
              dataTestId ? dataTestId : `form-select-field--${fieldIds.id}`
            }
            ref={ref}
            {...props}
          >
            <option value='' disabled>
              {placeholder}
            </option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div
            className={clsx(
              styles['form-input__affix'],
              styles['form-input__affix--end']
            )}
          >
            <Icon icon={FasChevronDown} size='md' />
          </div>
        </div>
      </FormItem>
    );
  }
);

FormSelect.displayName = 'FormSelect';
export default FormSelect;
