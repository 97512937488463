import { ISbAsset } from '@/components/storyblok/types/storyblok.interface';

export enum InternationalRateType {
  PAGY = 'payg',
  BOLT_ON = 'bolt-on',
}

export type SearchForm = {
  search?: string;
};

export type SimData = {
  landline?: string | number;
  mobile?: string | number;
  texts?: string | number;
};

export type CountryPAYG = {
  name: string;
  alpha3: string;
  flag: string;
  sim?: SimData[];
  cards?: Record<'mobile' | 'landline', string>[];
  euCountry?: boolean;
  favourites?: Record<string, string>[];
  travel?: Record<string, string>[];
  extras?: Record<string, string>[];
};

export type CountryBoltOn = {
  name: string;
  alpha3: string;
  flag: string;
  eucountry?: boolean;
  changed: string;
  internationalBolton?: {
    current?: SimData;
    historical: SimData[];
  };
};

export type FlagData = {
  name: string;
  flag: ISbAsset;
  code: string;
};

export type CountryData = {
  name: string;
  code: string;
  rates?: SimData;
};
