'use client';

import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FormSelect } from '@/components/momentum/components/forms/form-select';
import { Text } from '@/components/momentum/components/text';

import { loadBoltOnData, loadFlagImage, loadPAYGData } from './helpers';
import styles from './international-rates.module.scss';
import { CountryData, InternationalRateType, SearchForm } from './types';

export type InternationalRatesProps = {
  title: string;
  introduction: string;
  dataPath: string;
  defaultCountry: string;
  type: InternationalRateType;
};

export const InternationalRates: FC<InternationalRatesProps> = (props) => {
  const searchParams = useSearchParams();

  const [countryData, setCountryData] = useState<CountryData[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [country, setCountry] = useState<CountryData | undefined>(undefined);
  const [flag, setFlag] = useState<string | undefined>();
  const [error, setError] = useState<boolean>(false);

  const { register } = useForm<SearchForm>();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const nextCountry = countryData.find((entry) => entry.name === value);
    setCountry(nextCountry);

    if (nextCountry) {
      loadFlagImage(nextCountry.code, searchParams).then((story) => {
        setFlag(undefined);
        if (story?.flag.filename) {
          setFlag(story.flag.filename);
        }
      });
    }
  };

  useEffect(() => {
    setError(false);

    let adapter;
    switch (props.type) {
      case InternationalRateType.PAGY:
        adapter = loadPAYGData(props.dataPath);
        break;
      case InternationalRateType.BOLT_ON:
        adapter = loadBoltOnData(props.dataPath);
        break;
    }

    adapter.then((result) => {
      if (result.length === 0) {
        setError(true);
        return;
      }

      const countryList = result.map(({ name }) => name);
      setCountries(countryList);
      setCountryData(result);

      const index = result.findIndex((entry) => {
        const expected = props.defaultCountry.toLowerCase();
        const actual = entry.code.toLowerCase();
        return expected === actual;
      });
      const target = index >= 0 ? index : 0;

      loadFlagImage(result[target].code, searchParams).then((story) => {
        setCountry(result[target]);
        if (story?.flag.filename) {
          setFlag(story.flag.filename);
        }
      });
    });
  }, [props, searchParams, setCountryData, setCountries, setCountry, setFlag]);

  const Rate = (props: {
    label: string;
    rate?: string | number;
    units: string;
  }) => (
    <div>
      <Text size='T300' weight='light'>
        {props.label}
      </Text>
      {props.rate ? (
        <div className={styles['price']}>
          <Text size='T800' weight='light'>
            {props.rate}
          </Text>

          <Text size='T200' weight='light' italic={true}>
            {props.units}
          </Text>
        </div>
      ) : (
        <Text size='T800' weight='light'>
          n/a
        </Text>
      )}
    </div>
  );

  return (
    <>
      {error && (
        <Text>Country json data is either invalid or not available</Text>
      )}
      <div className={styles['international-rates']}>
        <div>
          <Text size='T600' weight='light' marginBottom={true}>
            {props.title}
          </Text>

          <Text size='T300' weight='light'>
            {props.introduction}
          </Text>
        </div>
        {country && countries.length && (
          <>
            <form
              data-testid='search-form'
              autoComplete='off'
              className={styles['country-form']}
            >
              <>
                {flag && (
                  <Image src={flag} alt={country.name} width={56} height={56} />
                )}
                <FormSelect
                  id='search'
                  data-testid='search'
                  options={countries}
                  value={country ? country.name : ''}
                  {...register('search')}
                  onChange={handleChange}
                />
              </>
            </form>

            <div className={styles['tariffs']}>
              <Rate
                label={'Landline'}
                rate={country.rates?.landline}
                units='p/min'
              />

              <Rate
                label={'Mobile'}
                rate={country.rates?.mobile}
                units='p/min'
              />

              <Rate label={'Text'} rate={country.rates?.texts} units='p/text' />
            </div>
          </>
        )}
      </div>
    </>
  );
};
