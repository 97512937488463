'use client';

import Script from 'next/script';
import { FC, useEffect, useState } from 'react';

export interface SearchIFrameProps {
  src: string;
  title: string;
}

export const SearchIFrame: FC<SearchIFrameProps> = ({
  src,
  ...props
}: SearchIFrameProps) => {
  const [url, setUrl] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const verticalUrl = searchParams.get('verticalUrl');
    const search = searchParams.toString();
    setUrl(
      `${src}${verticalUrl ? `/${verticalUrl}` : ''}${search ? `?${search}` : ''}`
    );
  });

  const onLoad = () => setLoaded(true);

  return (
    <div style={{ display: loaded ? 'block' : 'none' }}>
      <iframe
        id='embededIframe'
        onLoad={onLoad}
        src={url}
        width='100%'
        {...props}
      ></iframe>
      <Script
        type='text/javascript'
        src='/scripts/dynatrace/iframe-resizer.min.js'
        onLoad={() =>
          window.iFrameResize(
            {
              checkOrigin: false,
              onMessage: (event: Record<'message', string>) => {
                try {
                  const message = JSON.parse(event.message);

                  if (message['params']) {
                    const params = new URLSearchParams(message['params']);
                    const path = `${window.location.pathname}?${params}`;
                    history.replaceState(null, '', path);
                  }
                } catch {}
              },
            },
            '#embededIframe'
          )
        }
      />
    </div>
  );
};
